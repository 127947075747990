import { useEffect, useState } from "react";
import axios from "axios";
import { publicIpv4 } from "public-ip";
const config = require( "../Settings.json");


const AddDevice = () =>{

    const [data, setData] = useState([]);
    const [ManFact, setManu] = useState("");
    const [DevName, setDevName] = useState("");
    const [DevUID, setDevUID] = useState("");
    const [notes, getNote] = useState("No Notes");
    const [serialNumber, setSerialNum] = useState("");
    const [cond, setGrade] = useState("");
    const [devStatus, setStat] = useState("");
    const [purchaseDate, setDate] = useState(new Date());
    const [serialImg, setSerialImg] = useState(null);
    const [conditionImg, setCondImg] = useState(null);
    const [useImg, setUseImg] = useState(null);
    const [tick, setTick] = useState("#");
    const [isWipe, setWipe] = useState(false);
    const [compData, setComp] = useState(sessionStorage.getItem("getCompany"));
    const [optiVid, setVid] = useState(null);
   //const [CompHasID, setCompHasID] = useState(false);
    const [allIDs, setAllIDs] = useState([]);
    const [error, setError] = useState("");
    const [ipv4Add, setIP] = useState("");
    const [changesArr] = useState({});
    const formdata = new FormData();

    useEffect(() => {
        axios.put(config.DEV_URL + "/getCompanies").then(res =>{
            setData(res.data);
        }).catch(err =>{
            console.log(err);
        });
        //getLastID();
        getIP();
        axios.get(config.DEV_URL + "/getAllDeviceUniqueIDs").then(res => {
            if(res.data){
                setAllIDs(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);

    async function getIP(){
        await publicIpv4().then(res => {
            setIP(res);
        })
    }
    //NOT IN USE AS OF 30/01/2025
    /*const getLastID = (e) => {
        let a = document.getElementById("uniqueID");
        if(a.value){
            a.value = "";
        }
        axios.post(config.DEV_URL + "/getLatestDevicePerComp").then(res => {
            if(res.data.length != 0){
                setCompHasID(true);
                let str = res.data.map(r => r.DeviceUniqueID).toString();
                let str2 = str.split("-");
                let lastDigits = parseInt(str2[1]) + 1;
                let finalRes = str.replace(parseInt(str2[1]), lastDigits.toString());
                setDevUID(finalRes);
            }
            else{
                setCompHasID(false);
            }
        }).catch(err => {
            console.log(err);
        })
    }*/

    const getManu = (e) => {
        setManu(e.target.value);
        changesArr["Manufacturer"] = e.target.value;
        e.preventDefault();
    }
    const getDevName = (e) => {
        setDevName(e.target.value);
        changesArr["Device Name"] = e.target.value;
        e.preventDefault();
    }
    const getDevUID = (e) => {
        let isDuplicate = false;
        for(let i = 0; i < allIDs.length; i++){
            if(e.target.value.toUpperCase() == allIDs[i].DeviceUniqueID){
                isDuplicate = true;
                setError("ID Already In Use");
                changesArr["Equipment ID"] = "No ID";
            }
        }

        if(!isDuplicate){
            setError("");
            changesArr["Equipment ID"] = e.target.value.toUpperCase();
            setDevUID(e.target.value.toUpperCase());
        }
        e.preventDefault();
    }

    const getStatusDev = (e) => {
        setStat(e.target.value);
        changesArr["Device Status"] = e.target.value;
        e.preventDefault();
    }

    const getTicket = (e) => {
        setTick(e.target.value);
        changesArr["Ticket"] = e.target.value;
        e.preventDefault();
    }

    const getWiped = () => {
        if(document.getElementById("wipe").checked == true){
            setWipe(true);
            changesArr["Device Wiped"] = "Yes";
        }
        else{
            setWipe(false);
            changesArr["Device Wiped"] = "No";
        }
        
    }

    const getSerialNum = (e) => {
        setSerialNum(e.target.value);
        changesArr["Serial Number"] = e.target.value;
        e.preventDefault();
    }
    const getCondition = (e) => {
        setGrade(e.target.value);
        changesArr["Condition"] = e.target.value;
        e.preventDefault();
    }
    const getPurchaseDate = (e) => {
        setDate(e.target.value);
        changesArr["Date Purchased"] = e.target.value;
        console.log(e.target.value);
        e.preventDefault();
    }
    const getSerialImg = (e) => {
        if(e.target.files.length == 1 && e.target.files[0].size > (2048 * 2048)){
            window.alert("Only upload 2mb max");
            setSerialImg(null);
            return false;
        }
        else{
            changesArr["Serial Image"] = e.target.files[0].name;
            setSerialImg(e.target.files);
        }
        e.preventDefault();
    }
    const getConditionImg = (e) => {
        if(e.target.files.length == 1 && e.target.files[0].size > (2048 * 2048)){
            window.alert("Only upload 2mb max");
            setCondImg(null);
            return false;
        }
        else{
            changesArr["Condition Image"] = e.target.files[0].name;
            setCondImg(e.target.files);
        }
        e.preventDefault();
    }
    const getUseImg = (e) => {
        if(e.target.files.length == 1 && e.target.files[0].size > (2048 * 2048)){
            window.alert("Only upload 2mb max");
            setUseImg(null);
            return false;
        }
        else{
            changesArr["Usage Image"] = e.target.files[0].name;
            setUseImg(e.target.files);
        }
        e.preventDefault();
    }

    const getVideo = (e) => {
        if(e.target.files.length == 1 && e.target.files[0].size > (10240 * 10240)){
            window.alert("Only upload 10mb max");
            setVid(null);
            return false;
        }
        else{
            changesArr["Video"] = e.target.files;
            setVid(e.target.files);
        }
        e.preventDefault();
    }

    const getCName = (e) => {
        if(sessionStorage.getItem("getCompany") == "Axia"){
            setComp(e.target.value);
            //getLastID(e.target.value);
            changesArr["Company"] = e.target.value;
        }
        else{
            changesArr["Company"] = e.target.value;
            setComp(sessionStorage.getItem("getCompany"));
        }
        e.preventDefault();
    }
    const setNotes = (e) => {
        changesArr["Notes"] = e.target.value;
        getNote(e.target.value);
        e.preventDefault();
    }

    const handleSubmission = (e) => {


        formdata.append("files[]", serialImg[0]);
        formdata.append("files[]", conditionImg[0]);
        if(useImg){
            formdata.append("files[]", useImg[0]);
        }
        if(optiVid){
            formdata.append("files[]", optiVid[0]);
        }
        formdata.append("serialNum", serialNumber);
        formdata.append("comp", compData);

        axios.post(config.DEV_URL + "/AddDevice", {
            manufacturer: ManFact,
            devName: DevName,
            uniqueID: DevUID,
            sNum: serialNumber,
            condition: cond,
            serialImage: serialImg ? serialImg[0].name : null,
            conditionImage: conditionImg ? conditionImg[0].name : null,
            useImage: useImg ? useImg[0].name : null,
            devVideo: optiVid ? optiVid[0].name : null,
            PurchaseDate: purchaseDate,
            Status: devStatus,
            comp: compData,
            additionalNotes: notes,
            ticket: tick,
            isWiped: isWipe,
            devAddedBy: sessionStorage.getItem("user?name")
        }).then((result) => {
            if(result){
                axios.post(config.DEV_URL + "/Save-Image", formdata).then(async (res) => {
                    //console.log(res);
                    await axios.put(config.DEV_URL + "/trailingChanges", {
                        userName: sessionStorage.getItem("user?name"),
                        actionTaken: "Add Device",
                        actionLoc: "Add Device Page",
                        changeMade: JSON.stringify(changesArr),
                        IPv4Addy: ipv4Add
                    }).then(res2 => {
                        console.log(res2);
                    }).catch(err => {
                        console.log(err);
                    })
                    window.location = "/CurrentHardware";
                }).catch(err => {
                    console.log(err);
                })
            }
        }).catch(err => {
            console.log(err);
        })
        e.preventDefault();
    }


    return(
        <div>
         <div className="MainForms">
         <form encType="multipart/form-data" onSubmit={e => handleSubmission(e)}>
            <label>Manufacturer *</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e=>getManu(e)}></input>
            </div>
            <br></br>

            {
            sessionStorage.getItem("getCompany") == "Axia" ?
            <div>
            <label>Ticket Number</label>
            <br></br>
            <div className="formEffects">
            <input type="text" onChange={e=>getTicket(e)} defaultValue={"#"}></input>
            </div>
            <br></br></div> : null
            }

            <label>Device Name *</label>
            <br></br>
            <div className="formEffects">
            <input type="text" required onChange={e => getDevName(e)}></input>
            </div>
            <br></br>

            <label>Equipment ID</label>
            <br></br>
            <div className="formEffects">
                {error != "" || error != null ? <p>{error}</p> : <></>}
            <input type="text" id="uniqueID" onChange={e => getDevUID(e)} defaultValue={"LMS-"} style={{textTransform: "uppercase"}}></input>
            </div>
            <br></br> 

            {sessionStorage.getItem("getCompany") == "Axia" ?
            <div><label>Company *</label>
            <div className="formEffects">
            <select required onChange={e => getCName(e)}>
                <option selected>{sessionStorage.getItem("getCompany")}</option>
                {data != "No Records Found" ? data.map(list => (<option value={list.CompanyName}>{list.CompanyName}</option>)) : <option></option>}
            </select>
            <br></br>
            </div>
            <br></br>
            </div> : null
            }

            <div className="formEffects">
            <label>Serial Number *</label>
            <br></br>
            <input type="text" required placeholder="xxxxx-xxxxx-xxxxx" onChange={e => getSerialNum(e)}></input>
            <br></br>
            </div>

            <br></br>
            <label>Condition Grade *</label>
            <br></br>
            <div className="formEffects">
            <select required 
            title="Grade A: Excellent Condition 
            Grade B: Good Condition with minor wear and tear
            Grade C: Fair Condition with signs of usage
            Grade D: Broken, needs fixes, or purely in bad condition"
             onChange={e => getCondition(e)}>
                
                <option value="" disabled selected >Please Select An Option, Hover for info</option>
                <option value="Grade A" title="Excellent Condition">Grade A (Excellent)</option>
                <option value="Grade B" title="Good Condition with some minor wear and tear">Grade B (Good)</option>
                <option value="Grade C" title="Fair Condition with signs of usage">Grade C (Fair)</option>
                <option value="Grade D" title="Broken, needs fixes, or purely in bad condition">Grade D (Broken/Needs Attention)</option>
            </select>
            </div>

            <br></br>
            <label>Status of Device *</label>
            <br></br>
            <div className="formEffects">
            <select required onChange={e => getStatusDev(e)}>
                <option value="" disabled selected>Please Select An Option</option>
                <option value="New">Brand New (Condition Must be Grade A)</option>
                <option value="Renewed">Renewed</option>
                <option value="Recycled">Recycled</option>
                <option value="Pool">Pool</option>
                <option value="Disposable">Disposable</option>
            </select>
            </div>

            <label>Wiped?</label>
            <input type="checkbox" id="wipe" onClick={getWiped}></input>
            <br></br>
            <br></br>

            <br></br>
            <label>Device Purchase Date </label>
            <br></br>
            <div className="formEffects">
            <input type="date" onChange={e => getPurchaseDate(e)}></input>
            </div>
            <br></br>

            <label>Device Image With Serial Number Upload *</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile" required accept="image/png, image/jpeg" onChange={e => getSerialImg(e)}></input>
            </div>
            <br></br>

            <label>Condition of Device *</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile2" required accept="image/png, image/jpeg" onChange={e => getConditionImg(e)}></input>
            </div>
            <br></br>

            <label>Device In Use/In Packaging if New *</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile3" accept="image/png, image/jpeg" onChange={e => getUseImg(e)}></input>
            </div>
            <br></br>

            <label>Optional Video</label>
            <br></br>
            <div className="formEffects">
                <input type="file" name="myFile4" accept="video/mp4" onChange={e => getVideo(e)}></input>
            </div>
            <br></br>

            <label>Notes</label>
            <div className="formEffects">
                <textarea onChange={e => setNotes(e)} maxLength={5000}></textarea>
            </div>
            <br></br>
            
            {error == "" || error == null ?
            <button type="Submit" onSubmit={e => handleSubmission(e)}>Submit</button>
            :
            <button type="Submit" disabled>Submit</button>
            }
         </form>
         </div>
        </div>
    );
}

export default AddDevice;